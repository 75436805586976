<template>
<div>
    <Project v-if="works[0]" :objectContent="works[0]" :apiUrl="api_url" />
    <Project v-else-if="publications[0]" :objectContent="publications[0]" :apiUrl="api_url" />
    <Project v-else-if="exhibitions[0]" :objectContent="exhibitions[0]" :apiUrl="api_url" />
</div>
</template>

<script>
import Project from '../components/containers/Project.vue'
import gql from 'graphql-tag'

const getWorks = gql`
    query Works($slug: String!) {
      works(limit: 1, where: { slug: $slug }) {
        id
        slug
        title
        content
        photo_cover {
            caption
            formats
            url
        }
        photo_gallery {
            caption
            formats
            url
        }
        rel_pub {
            title
            slug
        }
        rel_exh {
            title
            slug
        }
        meta {
            technique
            size
            edition
            year
            location
            copyright
            part_collection
        }
        meta_custom {
            key
            value
        }
      }
    }
`;

const getPublications = gql`
    query Publications($slug: String!) {
      publications(limit: 1, where: { slug: $slug }) {
        id
        slug
        title
        content
        photo_cover {
            formats
            url
        }
        photo_gallery {
            formats
            url
        }
        rel_wrk {
            title
            slug
        }
        rel_exh {
            title
            slug
        }
        meta {
            publisher
            editors
            text
            designer
            year
        }
        meta_custom {
            key
            value
        }
      }
    }
`;

const getExhibitions = gql`
    query Exhibitions($slug: String!) {
      exhibitions(limit: 1, where: { slug: $slug }) {
        id
        slug
        title
        content
        photo_cover {
            formats
            url
        }
        photo_gallery {
            formats
            url
        }
        rel_pub {
            title
            slug
        }
        rel_wrk {
            title
            slug
        }
        meta {
            year
            location
            city
            country
        }
        meta_custom {
            key
            value
        }
      }
    }
`;

export default {
    
    data() {
        return {
            works: [],
            publications: [],
            exhibitions: [],
            api_url: process.env.VUE_APP_STRAPI_API_URL
        }
    },
    apollo: {
        works: {
            query: getWorks,
            variables() {
                return {
                    slug: this.$route.params.slug,
                };
            },
            update: data => data.works
        },
        publications: {
            query: getPublications,
            variables() {
                return {
                    slug: this.$route.params.slug,
                };
            },
            update: data => data.publications
        },
        exhibitions: {
            query: getExhibitions,
            variables() {
                return {
                    slug: this.$route.params.slug,
                };
            },
            update: data => data.exhibitions
        }
    },
    components: {
        Project
    }
}
</script>
