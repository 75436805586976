<template>
    <div v-if="objectContent" class="object-container">
        <transition name="txtpanel">
            <section id="txt-view" v-show="visibleText">
    
                <div class="split-col">
    
                    <div id="project-description" class="split-l">
                        <MarkedParser v-if="objectContent.content" :markedContent="objectContent.content" />
                    </div>

                    
                    <div class="split-r">
                        <div v-if="objectContent.rel_wrk && objectContent.rel_wrk != ''" id="related-works" class="related-list">
                            <p>Related Work(s)</p>
                            <ul v-if="objectContent.rel_wrk.length > 0">
                                <li class="link" v-for="wrk in objectContent.rel_wrk" :key="wrk.id">
                                    <router-link :to="'/projects/' + wrk.slug">
                                        <span @click="emitTitle" :data="wrk.title">{{ wrk.title }}</span>
                                    </router-link>
                                </li>
                            </ul>
                            <div v-else class="link">
                                <router-link :to="'/projects/' + objectContent.rel_wrk.slug">
                                    <span @click="emitTitle" :data="objectContent.rel_wrk.title">{{ objectContent.rel_wrk.title }}</span>
                                </router-link>
                            </div>
                        </div>

                        <div v-if="objectContent.rel_exh && objectContent.rel_exh != ''" id="related-exhibitions" class="related-list">
                            <p>Related Exhibition(s)</p>
                            <ul v-if="objectContent.rel_exh.length > 0">
                                <li class="link" v-for="exh in objectContent.rel_exh" :key="exh.id">
                                    <router-link :to="'/projects/' + exh.slug">
                                        <span @click="emitTitle" :data="exh.title">{{ exh.title }}</span>
                                    </router-link>
                                </li>
                            </ul>
                            <div v-else class="link">
                                <router-link :to="'/projects/' + objectContent.rel_exh.slug">
                                    <span @click="emitTitle" :data="objectContent.rel_exh.title">{{ objectContent.rel_exh.title }}</span>
                                </router-link>
                            </div>
                        </div>

                        <div v-if="objectContent.rel_pub && objectContent.rel_pub != ''" id="related-publications" class="related-list">
                            <p>Related Publication(s)</p>
                            <!-- <pre>{{objectContent.rel_pub}}</pre> -->
                            <ul v-if="objectContent.rel_pub.length > 0">
                                <li class="link" v-for="pub in objectContent.rel_pub" :key="pub.id">
                                    <router-link :to="'/projects/' + pub.slug">
                                        <span @click="emitTitle" :data="pub.title">{{ pub.title }}</span>
                                    </router-link>
                                </li>
                            </ul>
                            <div v-else class="link">
                                <router-link :to="'/projects/' + objectContent.rel_pub.slug">
                                    <span @click="emitTitle" :data="objectContent.rel_pub.title">{{ objectContent.rel_pub.title }}</span>
                                </router-link>
                            </div>
                        </div>
    
    
    
                        <ul id="project-meta">
    
                            <!-- Object meta -->
                            <li v-if="objectContent.meta.year">
                                <span>Year</span>
                                <span>{{ objectContent.meta.year }}</span>
                            </li>
    
                            <li v-if="objectContent.meta.location">
                                <span>Location</span>
                                <span>{{ objectContent.meta.location }}</span>
                            </li>
    
                            <!-- Publications meta -->
                            <li v-if="objectContent.meta.publisher">
                                <span>Publisher</span>
                                <span>{{ objectContent.meta.publisher }}</span>
                            </li>
    
                            <li v-if="objectContent.meta.designer">
                                <span>Design</span>
                                <span>{{ objectContent.meta.designer }}</span>
                            </li>
    
                            <li v-if="objectContent.meta.editors">
                                <span>Editor(s)</span>
                                <span>{{ objectContent.meta.editors }}</span>
                            </li>
    
                            <li v-if="objectContent.meta.text">
                                <span>Text(s)</span>
                                <span>{{ objectContent.meta.text }}</span>
                            </li>
    
                            <!-- Exhibitions meta -->
                            <li v-if="objectContent.meta.city">
                                <span>City</span>
                                <span>{{ objectContent.meta.city }}</span>
                            </li>
    
                            <li v-if="objectContent.meta.country">
                                <span>Country</span>
                                <span>{{ objectContent.meta.country }}</span>
                            </li>
    
                            <!-- Works meta -->
                            <li v-if="objectContent.meta.technique">
                                <span>Technique</span>
                                <span>{{ objectContent.meta.technique }}</span>
                            </li>
    
                            <li v-if="objectContent.meta.size">
                                <span>Size</span>
                                <span>{{ objectContent.meta.size }}</span>
                            </li>
    
                            <li v-if="objectContent.meta.edition">
                                <span>Edition</span>
                                <span>{{ objectContent.meta.edition }}</span>
                            </li>
    
                            <li v-if="objectContent.meta.copyright">
                                <span>Copyright</span>
                                <span>{{ objectContent.meta.copyright }}</span>
                            </li>
    
                            <li v-if="objectContent.meta.part_collection">
                                <span>Collection of</span>
                                <span>{{ objectContent.meta.part_collection }}</span>
                            </li>

                            <li v-for="item in objectContent.meta_custom" :key="item.id">
                                <span>{{ item.key }}</span>
                                <span>{{ item.value }}</span>
                            </li>
    
                            <!-- <li v-if="objectContent.meta.exhibited_at && objectContent.meta.exhibited_at.length > 0">
                                <span>Exhibited at</span>
                                <ul id="project-exhibited-list">
                                    <li v-for="item in objectContent.meta.exhibited_at" :key="item.id">
                                        {{ item.exh_title ? item.exh_title + '. ' : ''}} {{ item.exh_location }}. {{ item.exh_year }}
                                    </li>
                                </ul>
                            </li> -->
    
                        </ul>
                    </div>
                </div>
            </section>
        </transition>
    
        <transition name="imgpanel">
            <section ref="imgView" id="img-view" v-show="visibleImg">
                <div class="gallery">
                    <div v-if="objectContent.photo_cover" id="cover" :class="randomPos()">
                        <img v-if="objectContent.photo_cover.formats.large" :src="apiUrl + objectContent.photo_cover.formats.large.url" :alt="'fsh-project-' + objectContent.slug + 'l'">
                        <img v-else-if="objectContent.photo_cover.formats.medium" :src="apiUrl + objectContent.photo_cover.formats.medium.url" :alt="'fsh-project-' + objectContent.slug + 'm'">
                        <img v-else-if="objectContent.photo_cover.formats.small" :src="apiUrl + objectContent.photo_cover.formats.small.url" :alt="'fsh-project-' + objectContent.slug + 's'">
                        <img v-else-if="objectContent.photo_cover.formats.thumbnail" :src="apiUrl + objectContent.photo_cover.url" :alt="'fsh-project-' + objectContent.slug">
                        <span v-if="objectContent.photo_cover.caption" class="img-caption">{{ objectContent.photo_cover.caption }}</span>
                    </div>
                    <div v-for="image in objectContent.photo_gallery" id="gallery" :key="image.id" :class="randomPos()">
                        <img v-if="image.formats.large" :src="apiUrl + image.formats.large.url" :alt="'fsh-project-' + objectContent.slug + 'l'">
                        <img v-else-if="image.formats.medium" :src="apiUrl + image.formats.medium.url" :alt="'fsh-project-' + objectContent.slug + 'm'">
                        <img v-else-if="image.formats.small" :src="apiUrl + image.formats.small.url" :alt="'fsh-project-' + objectContent.slug + 's'">
                        <img v-else-if="image.formats.thumbnail" :src="apiUrl + image.url" :alt="'fsh-project-' + objectContent.slug + 's'">
                        <span v-if="image.caption" class="img-caption">{{ image.caption }}</span>
                    </div>
                </div>
            </section>
        </transition>
        <button @click="scrollToTop" id="to-top" class="btn-totop hide-btn">↑</button>
    </div>
</template>

<script>
import MarkedParser from '@/components/MarkedParser'

import { gsap, Circ } from 'gsap'
export default {
    data() {
        return {
            onTxt: false,
            onImg: true,
        }
    },
    components: {
        MarkedParser
    },
    computed: {
        visibleImg() {
            return this.$store.state.img
        },
        visibleText() {
            return this.$store.state.text
        },
    },
    methods: {
        randomPos() {
            const pos = ["l", "r"];
            const random = Math.floor(Math.random() * pos.length);
            return 'align-' + pos[random]
        },
        scrollToTop() {
            window.scroll({
                top: 0,
                behavior: 'smooth'
            });
        },
        emitTitle(target){
            const title = target.target.attributes.data.value 
            this.$store.commit('changeCurrent', title)
        },
    },
    mounted() {
        this.$store.commit('changeCurrent', this.objectContent.title)

        gsap.fromTo('#img-view', { opacity: 0, y: 10 }, { ease: Circ.easeOut, opacity: 1, y: 0 });

        const toTopBtn = document.getElementById("to-top");
        var myScrollFunc = function() {
            var y = window.scrollY;
            if (y >= 800) {
                toTopBtn.classList.add("show-btn");
                toTopBtn.classList.remove("hide-btn");
            } else {
                toTopBtn.classList.add("hide-btn");
                toTopBtn.classList.remove("show-btn");
            }
        };
        window.addEventListener("scroll", myScrollFunc);


    },
    created() {
        document.addEventListener('keypress', logKey);

        function logKey(e) {
            if (e.code == "KeyR") {
                this.randomPos();
                console.log('Pressed');
            }
        }
    },
    props: {
        objectContent: Object,
        apiUrl: String
    }
}
</script>
